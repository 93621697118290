
import WelcomePage from './components/WelcomePage.js';
import './styles/App.css';

function App() {
  return (
    <div className="App">
      <WelcomePage />
    </div>
  );
}

export default App;
